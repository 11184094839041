header {
    background-image: url("./images/ultraculture-office.jpg");
    height: auto;
    width: 100%;
}

h1 {
    font-weight: 900;
    letter-spacing: -1px;
    line-height: 1;
    margin-bottom: 10px;
    color: rgba(255, 255, 255,1);
}

p {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255,1);
    line-height: 1.45rem;
}

.outerBox {
    padding: 5% 10% 10% 5%;
    background: rgba(43, 43, 43, 0.5);
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.innerBox {
    background: rgba(43, 43, 43, 0.5);
    margin: 10px;
    padding: 20px;
}

.col1 {
    max-width: 400px;
}

.col2 {

}