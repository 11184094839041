button {
    background-color: black;
    color: white;
    padding: 7px 7px 7px 7px;
    margin-top: 3px;
    margin-bottom: 6px;
    border-radius: 0%;
    font-size: 0.9em;
    text-transform: uppercase;
    border: none;
}

.verticals-copy {

}

.verticals-flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.verticals-item {
    width: 30%;
    min-width: 200px;
    background: rgba(101, 101, 101, 0.5);
    padding: 10px;
    margin: 10px;
}

#verticals {
    background-color: white;
    color: black;
    padding: 30px 40px 60px 40px;
}

#verticals h2 {
}

#verticals h3 {
    text-align: left;
    text-transform: uppercase;
}

#verticals p {
    color: black;
}
