html {
    font-size: 16px;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    background-color: #282c34;
    color: white;
}

h1 {
    font-size: 3.75em;
}

h2 {
    font-size: 2.75em;
}

h3 {
    font-size: 1.2em;
}

p {
    font-size: 1em;
}

a {
    color: #61dafb;
}