#hiring {
    background-color: white;
    color: black;
}

#hiring p {
    color: black;
}

.hiringInner {
    padding: 40px;
}